<template>
  <el-card v-loading="isLoading">
    <template #header>
      <el-row type="flex" justify="space-between" align="center">
        <h4>Fechamento de Caixa</h4>
      </el-row>
    </template>
    <el-descriptions border columns="2">
      <el-descriptions-item>
        <template #label> Data: </template>
        {{ dateFormatter.format(new Date()) }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label> Operador: </template>
        {{ $store.state?.user?.name }}
      </el-descriptions-item>
    </el-descriptions>
    <form>
      <el-select
        remote
        :loading="!firms"
        @visible-change="fetchFirms"
        value-key="uid"
        v-model="cashier.firm_id"
        size="medium"
      >
        <template #prefix>Empresa:</template>
        <el-option
          v-for="item in Firms"
          :key="item.uid"
          :label="item.name"
          :value="item.uid"
        >
        </el-option>
      </el-select>
      <el-date-picker
        v-model="cashier.closed_at"
        type="date"
        :disabledDate="(d) => new Date(d) > new Date()"
        size="medium"
        placeholder="Data de Fechamento:"
        format="DD/MM/YYYY"
      >
      </el-date-picker>
      <el-row :gutter="4" type="flex">
        <el-col :md="12" :sm="24">
          <base-input
            v-model="cashier.code"
            mask="#*"
            label="Caixa:"
          ></base-input>
        </el-col>
        <el-col :md="12" :sm="24">
          <base-input v-model="cashier.op" label="Operator:"></base-input>
        </el-col>
      </el-row>
      <el-row type="flex" justify="start">
        <h5>MOVIMENTAÇÃO DO DIA</h5>
      </el-row>
      <base-input
        v-model="cashier.coin"
        type="money"
        label="Moeda:"
      ></base-input>
      <base-input
        v-model="cashier.money"
        type="money"
        label="Dinheiro:"
      ></base-input>
      <base-input
        v-model="cashier.credit"
        type="money"
        label="Cartão de Crédito:"
      ></base-input>
      <base-input
        v-model="cashier.debit"
        type="money"
        label="Cartão de Débito:"
      ></base-input>
      <base-input v-model="cashier.pix" type="money" label="PIX:"></base-input>
      <base-input
        v-model="cashier.delivery"
        type="money"
        label="Entregas:"
      ></base-input>
      <base-input
        v-model="cashier.debts"
        type="money"
        label="Convênio:"
      ></base-input>
      <base-input
        v-model="cashier.ticket"
        type="money"
        label="Ticket:"
      ></base-input>
      <base-input
        v-model="cashier.expenses"
        type="money"
        label="Despesas:"
      ></base-input>
      <el-row type="flex" justify="start">
        <h5>REFORÇO</h5>
      </el-row>
      <base-input
        v-model="cashier.start"
        type="money"
        label="Reforço:"
      ></base-input>
      <el-row type="flex" justify="start">
        <h5>DIVERSOS</h5>
      </el-row>
      <el-row :gutter="4" type="flex">
        <el-col :md="12" :sm="24">
          <base-input
            v-model="discount"
            type="money"
            label="Desconto:"
          ></base-input>
        </el-col>
        <el-col :md="12" :sm="24">
          <base-input v-model="clients" label="Clientes:"></base-input>
        </el-col>
      </el-row>
      <el-row :gutter="4" type="flex">
        <el-col :md="12" :sm="24">
          <base-input
            v-model="cashier.canceled"
            type="money"
            label="Cancelamento de Venda:"
          ></base-input>
        </el-col>
        <el-col :md="12" :sm="24">
          <base-input
            v-model="cashier.canceled_reasons"
            label="Motivo:"
          ></base-input>
        </el-col>
      </el-row>
      <base-input
        v-model="cashier.withdrawal"
        type="money"
        label="Sangria:"
      ></base-input>
      <el-row type="flex" justify="start">
        <h5>FINALIZADORAS</h5>
      </el-row>
      <base-input
        v-model="total"
        type="money"
        disabled
        label="Total em Caixa:"
      ></base-input>
    </form>
    <el-divider class="negative-margin"></el-divider>
    <el-row type="flex" justify="end">
      <el-button
        type="text"
        :loading="isLoadingSave"
        class="button"
        @click="save"
        >Salvar</el-button
      >
    </el-row>
  </el-card>
</template>
<script>
import { ElNotification } from "element-plus";
//import { ElNotification } from "element-plus";
//import VueApexCharts from "vue3-apexcharts";
import BaseInput from "../components/BaseInput.vue";
export default {
  name: "CashierClose",
  components: {
    BaseInput,
  },
  computed: {
    Firms() {
      return this.firms || [];
    },
    isNew() {
      return !("uid" in this.cashier);
    },
  },
  mounted() {
    this.$watch(
      function () {
        return Object.values(this.cashier);
      },
      function () {
        this.updateTotal();
      },
      { deep: true }
    );
    this.checkIfExistRejectedClosing();
  },
  data() {
    return {
      isLoadingSave: false,
      isLoading: true,
      firms: null,
      discount: 0,
      total: 0,
      clients: null,
      dateFormatter: new Intl.DateTimeFormat("pt-BR", {
        dateStyle: "long",
        timeStyle: "short",
      }),
      currencyFormatter: new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }),
      cashier: {},
    };
  },
  methods: {
    totalSells() {
      const total = Number(this.total) || 0;
      const withdrawal = Number(this.cashier?.withdrawal) || 0;
      //const discount = Number(this.discount) || 0;
      const start = Number(this.cashier.start) || 0;
      return Math.max(total + withdrawal - start, 0);
    },
    checkIfExistRejectedClosing() {
      const url = new URL(`${this.$store.state.apiUrl}cashiers`);
      url.search = new URLSearchParams({
        user_id: this.$store.state?.user?.uid,
        status: "rejected",
      });
      fetch(url, {
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then((r) => r.json())
        .then((json) => {
          if (json?.length) {
            const rejected = json[0];
            this.$confirm(
              "Você tem um fechamento de caixa rejeitado, deseja recuperar?",
              {
                confirmButtonText: "Recuperar",
                cancelButtonText: "Novo fechamento",
                type: "warning",
              }
            )
              .then(() => {
                this.cashier = rejected;
                this.cashier.firm_id = rejected?.firm?.uid;
                this.firms = [rejected.firm];
              })
              .catch(() => {});
          }

          this.isLoading = false;
        });
    },
    fetchFirms(v) {
      const url = new URL(`${this.$store.state.apiUrl}firms`);
      url.search = new URLSearchParams({ type: "shop" });
      if (v)
        fetch(url, {
          credentials: "include",
        })
          .then((response) => {
            if (response.status === 200) return response.json();
            else return response.text();
          })
          .then((json) => {
            this.firms = json;
            this.$emit("fetch-firms", json);
          });
    },
    save() {
      this.isLoadingSave = true;

      fetch(
        `${this.$store.state.apiUrl}cashiers${
          this.isNew ? "" : "/" + this.cashier?.uid
        }`,
        {
          credentials: "include",
          method: this.isNew ? "POST" : "PUT",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({
            ...this.cashier,
            status: "created",
            total_sells: this.totalSells(),
            type: 'shop',
            discount: this.discount,
            discount_clients: this.clients,
            total_cashier: this.total,
          }),
        }
      )
        .then((response) => {
          if (response.status === 200) return response.json();
          else throw response.json();
        })
        .then(
          () => {
            ElNotification.success({
              title: this.isNew
                ? "Fechamento de caixa cadastrado com sucesso."
                : "Fechamento de caixa atualizado com sucesso.",
            });
            this.cashier = {};
            this.total = 0;
            this.discount = 0;
            this.clients = "";
          },
          async (e) => {
            ElNotification.error({
              title: "Não foi possível cadastrar o fechamento de caixa",
              message: (await e).message,
            });
          }
        )
        .finally(() => (this.isLoadingSave = false));
    },
    updateTotal() {
      let t = 0;
      t += Number(this.cashier?.delivery) || 0;
      t += Number(this.cashier?.debts) || 0;
      t += Number(this.cashier?.expenses) || 0;
      //t -= Number(this.cashier?.start) || 0;
      //t += Number(this.cashier?.canceled) || 0;
      t += Number(this.cashier?.coin) || 0;
      t += Number(this.cashier?.money) || 0;
      t += Number(this.cashier?.credit) || 0;
      t += Number(this.cashier?.ticket) || 0;
      t += Number(this.cashier?.debit) || 0;
      t += Number(this.cashier?.pix) || 0;
      this.total = Math.max(t, 0);
    },
  },
};
</script>
<style scoped>
.el-row {
  margin-bottom: 8px;
}
.m-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.negative-margin {
  margin-left: -20px !important;
  margin-right: -20px !important;
  width: inherit;
}
</style>